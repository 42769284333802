import { SET_COUNTRIES } from './countrySelectorConstants';
import genericErrorHandler from 'app/utils/serverErrors';
import { ajaxRequest } from 'app/utils/helpers';

export const setCountries = (countries) => ({
  type: SET_COUNTRIES,
  countries
});

export const getCountries = (getCountriesUrl) =>
  dispatch =>
    ajaxRequest('GET', getCountriesUrl).then((res) => dispatch(setCountries(res.countries)), ({ status }) => genericErrorHandler(dispatch, status));
