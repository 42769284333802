import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  FormError,
  FormGroup,
  FormActions
} from 'react-forms';
import * as CountrySelectorActions from './countrySelectorActions';
import OptionIntl from '../optionIntl/optionIntl';

class CountrySelector extends Component {
  constructor() {
    super();

    this.getSingleCountry = this.getSingleCountry.bind(this);
    this.getMultiplCountries = this.getMultiplCountries.bind(this);
  }

  componentDidMount() {
    const {
      getCountries,
      countriesUrl
    } = this.props;

    getCountries(countriesUrl);
  }

  componentWillReceiveProps(nextProps) {
    const {
      setInputValue,
      formName,
      countries
    } = nextProps;

    const hasSingleCountry = countries.length === 1;
    if (hasSingleCountry) {
      setInputValue(formName, {
        country: countries[0].isocode
      });
    }
  }

  getMultiplCountries() {
    const { countries, ...formProps } = this.props;
    return (
      <FormGroup
        {...formProps}
      >
        <div className="form-input">
          <div className="country-selector">
            <label className="form-input__label" htmlFor="country">
              <FormattedMessage id="addressForm.country.multi" />
            </label>
            <select className="country-selector__select" name="country" id="country">
              <OptionIntl value="" id="addressForm.country.default" />
              {
                countries.map(country =>
                  <option key={`country${country.isocode}`} value={country.isocode}>{country.name}</option>
                )
              }
            </select>
          </div>
          <FormError forInput="country" />
        </div>
      </FormGroup>
    );
  }

  getSingleCountry() {
    const {
      countries, formName, hiddenInput, ...formProps
    } = this.props;
    const country = countries[0];
    return (
      <FormGroup
        formName={formName}
        {...formProps}
      >
        <div className={`form-input ${hiddenInput && 'hidden'}`}>
          <div className="country-selector">
            <FormattedHTMLMessage
              id={`addressForm.country.single.${formName.toLowerCase().replace('-', '')}`}
              values={{
                0: country.name
              }}
            />
            <input type="hidden" value={country.isocode} autoComplete="off" name="country" />
          </div>
          <FormError forInput="country" />
        </div>
      </FormGroup>
    );
  }

  render() {
    const hasMultiplCountries = this.props.countries.length > 1;
    const hasSingleCountry = this.props.countries.length === 1;

    if (hasMultiplCountries) {
      return <div>{this.getMultiplCountries()}</div>;
    }

    if (hasSingleCountry) {
      return <div>{this.getSingleCountry()}</div>;
    }

    return null;
  }
}

CountrySelector.defaultProps = {
  countries: []
};

CountrySelector.propTypes = {
  countriesUrl: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  getCountries: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  hiddenInput: PropTypes.bool
};

const mapStateToProps = (state) => ({
  countries: state.CountrySelector && state.CountrySelector.countries
});

export default connect(mapStateToProps, {
  ...CountrySelectorActions,
  setInputValue: FormActions.setInputValue
})(CountrySelector);
