import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FormGroup, FormError, FormActions } from 'react-forms';
import * as dateUtils from './dobFormGroupUtils';
import OptionIntl from '../optionIntl/optionIntl';

class DOBFormGroup extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      setInputValue,
      formName,
      dobForm
    } = this.props;

    const nextDob = dateUtils.getDOBIfComplete(nextProps.dobForm.values);
    const dob = dateUtils.getDOBIfComplete(dobForm.values);
    const hasDate = !!dateUtils.getDOBIfComplete(dobForm.values);
    const hasDateChanged = dob !== nextDob;

    if (hasDate && hasDateChanged) {
      setInputValue(formName, {
        dob: nextDob
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      formName,
      triggerValidate,
      dobForm
    } = this.props;

    const prevDob = dateUtils.getDOBIfComplete(prevProps.dobForm.values);
    const dob = dateUtils.getDOBIfComplete(dobForm.values);
    const hasDate = !!dateUtils.getDOBIfComplete(dobForm.values);
    const hasDateChanged = dob !== prevDob;

    if (hasDate && hasDateChanged) {
      triggerValidate(formName, true, false);
    }
  }

  render() {
    const {
      maxAge,
      minAge,
      dobForm
    } = this.props;
    const dob = dateUtils.getDOBIfComplete(dobForm.values);

    return (
      <FormGroup
        {...this.props}
      >
        <input autoComplete="off" name="dob" type="hidden" value={dob || ''} />
        <div className="row form-row" data-form-error="dob">
          <div className="columns medium-12">
            <legend className="form-input__label">
              <FormattedMessage
                id="security.ui.register.dob"
                description="Date of Birth"
              />
            </legend>
            <div className="row">
              <div className="columns small-4">
                <div className="form-input form-input--no-margin">
                  <select name="day">
                    <OptionIntl value="" id="security.ui.register.dob.day" />
                    {
                      dateUtils.getMaxCalendarDays().map(n =>
                        <option key={`day${n}`} value={n}>{n}</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className="columns small-4">
                <div className="form-input form-input--no-margin">
                  <select name="month">
                    <OptionIntl value="" id="security.ui.register.dob.month" />
                    {
                      dateUtils.getMonthNames().map((monthName, index) =>
                        (
                          <FormattedMessage key={`month${monthName}`} id={monthName}>
                            {(message) => <option value={index + 1}>{message}</option>}
                          </FormattedMessage>
                        )
                      )
                    }
                  </select>
                </div>
              </div>
              <div className="columns small-4">
                <div className="form-input form-input--no-margin">
                  <select name="year">
                    <OptionIntl value="" id="security.ui.register.dob.year" />
                    {
                      dateUtils.getYears(minAge, maxAge).map(year =>
                        <option key={`year${year}`} value={year}>{year}</option>
                      )
                    }
                  </select>
                </div>
              </div>
              <div className="columns small-12">
                <FormError forInput="dob" />
              </div>
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

DOBFormGroup.defaultProps = {
  minAge: 16,
  maxAge: 100
};

DOBFormGroup.propTypes = {
  formName: PropTypes.string.isRequired,
  triggerValidate: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  dobForm: PropTypes.shape({
    values: PropTypes.object.isRequired
  }),
  maxAge: PropTypes.number,
  minAge: PropTypes.number
};

const mapStateToProps = ({ Forms }, { formName }) => ({
  dobForm: Forms[formName] || { values: {} }
});

export default connect(mapStateToProps, FormActions)(DOBFormGroup);
