import { SET_AVAILABLE_REGIONS, SET_CURRENT_REGION } from './regionSelectorConstants';
import genericErrorHandler from 'app/utils/serverErrors';
import { ajaxRequest } from 'app/utils/helpers';

export const setRegions = (regions) => ({
  type: SET_AVAILABLE_REGIONS,
  regions
});

export const setRegion = (region) => ({
  type: SET_CURRENT_REGION,
  selectedRegion: region
});

export const getRegions = (getRegionsUrl, isoCode) =>
  dispatch =>
    ajaxRequest('GET', getRegionsUrl, { isoCode }).then((res) => dispatch(setRegions(res)), ({ status }) => genericErrorHandler(dispatch, status));
