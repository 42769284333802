import React from 'react';
import PropTypes from 'prop-types';
import CartItem from '../cartItem/cartItem';

const ReviewList = ({
  products, isCartFetching, children, className, showTotals
}) => (
  <div className="cart-list-container">
    <ul className={`cart-list ${className}`}>
      {products.map(
        product => (<CartItem
          key={product.id}
          product={product}
          className={className}
          isCartFetching={isCartFetching}
          showTotals={showTotals}
        />)
      )}
    </ul>
    { children }
  </div>
);


ReviewList.propTypes = {
  products: PropTypes.array.isRequired,
  isCartFetching: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
};

ReviewList.defaultProps = {
  className: ''
};

export default ReviewList;
