import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import UsernameAndPassword from '../usernameAndPasswordFormGroup/usernameAndPasswordFormGroup';

const LoginForm = ({
  onSubmit,
  formGroups,
  validation,
  forgotPasswordUrl,
  migrationMsg,
  placeholders,
  buttonDisabled
}) => {
  const handlersAndValidation = {
    handleValidForm: formGroups.resolve,
    handleInvalidForm: formGroups.reject,
    validation
  };

  return (
    <form className="auth-form auth-form--login" name="loginForm" onSubmit={onSubmit} noValidate>
      <UsernameAndPassword
        formName={formGroups.getName('usernameAndPassword')}
        {...handlersAndValidation}
        placeholders={placeholders}
      />
      <div dangerouslySetInnerHTML={{ __html: migrationMsg }} className="auth-form__warning" />

      <div className="row">
        <div className="columns small-12 btn-login">
          {
            !migrationMsg &&
            <button
              onKeyPress={(e) => (e.key === 'Enter' ? onSubmit : '')}
              onClick={onSubmit}
              className={`button button--stretch auth-form__sign-in-btn ${buttonDisabled ? 'disabled' : ''}`}
              id="Iniciar Sesión"
              disabled={buttonDisabled}
            >
              <FormattedMessage id="security.ui.login.title" description="Sign In" />
            </button>
          }
        </div>
        <div className="columns small-12">
          <p className="button button--link auth-form__forgot-password">¿Olvidaste tu contraseña? <Link to={forgotPasswordUrl} href={forgotPasswordUrl} >Recupérala aquí</Link></p>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formGroups: PropTypes.object.isRequired,
  validation: PropTypes.shape({
    rules: PropTypes.object.isRequired,
    messages: PropTypes.object.isRequired
  }),
  forgotPasswordUrl: PropTypes.string.isRequired,
  migrationMsg: PropTypes.string,
  placeholders: PropTypes.object,
  buttonDisabled: PropTypes.bool
};

export default LoginForm;
