import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FormError, FormGroup } from 'react-forms';
import PasswordTextInput from '../passwordTextInput/passwordTextInput';

const NewPasswordForm = ({
  name,
  formName,
  onSubmit,
  resetBtnTxt,
  ...handlersAndValidation
}) => (
  <form name={name} noValidate onSubmit={onSubmit}>
    <FormGroup
      formName={formName}
      {...handlersAndValidation}
    >
      <div className="reset-password-form">
        <label className="form-input__label" htmlFor="password">
          <FormattedMessage
            id="security.ui.resetPassword.new.password"
            description="New Password"
          />
        </label>
        <div className="reset-password-form__pass-input">
          <PasswordTextInput typeKey="reset">
            <input
              className="form-input__input"
              type="password"
              id="password"
              name="password"
              value=""
            />
          </PasswordTextInput>
        </div>
        <FormError forInput="password" />
        <span className="form-input__input-hint form-input__input-hint--multi">
          <FormattedMessage id="security.ui.auth.password.hint" description="hint for password" />
        </span>

        <label className="form-input__label" htmlFor="password2">
          <FormattedMessage
            id="security.ui.resetPassword.confirm.password"
            description="Confirm Password"
          />
        </label>
        <div className="reset-password-form__pass-input">
          <PasswordTextInput typeKey="reset">
            <input
              className="form-input__input"
              type="password"
              name="password2"
              id="password2"
              value=""
            />
          </PasswordTextInput>
        </div>
        <FormError forInput="password2" />
      </div>
      <button className="button button--stretch">
        <FormattedMessage id={resetBtnTxt} description="Reset Password" />
      </button>
    </FormGroup>
  </form>
);

NewPasswordForm.defaultProps = {
  resetBtnTxt: 'security.ui.resetPassword.submit'
};

NewPasswordForm.propTypes = {
  resetBtnTxt: PropTypes.string,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NewPasswordForm;
