import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

export const getLastNumbers = (number, numberOfChars) => number && number.slice(-numberOfChars);

export const getPostalCode = (address) => {
  if (!address) {
    return '';
  }

  const { region, postalCode } = address;

  return (region && region.isocodeShort) ? `${region.isocodeShort} ${postalCode}` : postalCode;
};

export const getPhoneLine = (phone, cellPhoneNumber) => {
  if (!isEmpty(phone)) {
    return (
      <p>
        <FormattedMessage id="checkout.delivery.contact.tel" /> {phone}<br />
        <FormattedMessage id="checkout.delivery.contact.cel" /> {cellPhoneNumber}
      </p>
    );
  }

  return (
    <p>
      <FormattedMessage id="checkout.delivery.contact.cel" /> {cellPhoneNumber}
    </p>
  );
};

export const getFullAddress = (address, countries = []) => {
  if (!address) {
    return '';
  }

  const {
    firstName,
    secondFirstName,
    lastName,
    secondLastName,
    region,
    city,
    town,
    colonia,
    postalCode,
    line1,
    line2,
    unitNumber,
    landmark,
    country
  } = address;
  const defaultRegion = region || {};
  const defaultCity = city || town;
  const countryName = countries.filter(c => c.isocode === country)[0] || {};

  return (
    <address>
      {firstName}{secondFirstName && ` ${secondFirstName}`} {lastName}{secondLastName && ` ${secondLastName}`}<br />
      {line1} {line2}, {unitNumber}{unitNumber && ', '}{colonia}, {defaultCity}, {defaultRegion.isocode}, {countryName.name} {postalCode}<br />
      {landmark}{landmark && <br />}
    </address>
  );
};
