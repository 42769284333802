import React, { Component } from 'react';

const scrollToTopHOC = (WrappedComponent) => (
  class RequiredAuth extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
);

export default scrollToTopHOC;
