import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

const LinkExternal = ({ to, isExternal, children, backButtonClass }) =>
  (
    isExternal ?
      <a className={`button button--tertiary ${backButtonClass } `} href={to}>
        {children}
      </a> :
      <Link className={`button button--tertiary ${backButtonClass }`} to={to}>
        {children}
      </Link>
  );

LinkExternal.defaultProps = {
  isExternal: false
};

LinkExternal.propTypes = {
  to: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  children: PropTypes.any
};

export default LinkExternal;
