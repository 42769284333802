import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import PaymentIcon from 'app/paymentIcons/paymentIcon/paymentIcon';
import { NUMBER_OF_CHART_TO_DISPLAY, CARD_NAME_MAPPING } from './paymentMethodConstants';
import { getLastNumbers, getFullAddress } from 'app/utils/addressHelpers';
import { PAYMENT_METHOD } from 'app/checkout/components/checkoutPayment/checkoutPaymentConstants';

const getSavedCardProperty = (savedCardPropertyData, propertyName) =>
  savedCardPropertyData && savedCardPropertyData.filter(el => (el.name === CARD_NAME_MAPPING[propertyName]))[0].value;

const getCardProperty = (formCardData, savedCardPropertiesData, propertyName) => (
  isEmpty(formCardData) ? getSavedCardProperty(savedCardPropertiesData, propertyName) : formCardData[propertyName]
);

const PaymentMethod = ({ paymentInfo, cardDetails, children }) => {
  const cardDetailsData = (cardDetails && cardDetails.values) ? cardDetails.values : {};
  const { properties } = paymentInfo;

  return (
    <div className="cart-order-summary__payment-method-container">
      <div className="row">
        <div className="columns small-8">
          <div className="cart-order-summary__section-title">
            <h5>
              <FormattedMessage id="order.billing.payment.method" description="Payment method" />
            </h5>
          </div>
          {
            (() => {
              switch (paymentInfo.type) {
                case PAYMENT_METHOD.PAYPAL:
                  return (
                    <div className="cart-order-summary__review-info card-wrapper">
                      <PaymentIcon className="card" iconName="paypal" />
                      <span className="card-name">{PAYMENT_METHOD.PAYPAL}</span>
                    </div>
                  );
                case PAYMENT_METHOD.CASHONDELIVERY:
                  return (
                    <div className="cart-order-summary__review-info card-wrapper">
                      <PaymentIcon className="card" iconName="cash" />
                      <span className="card-name">
                        <FormattedMessage id="cart.summary.paymentmethod.cashondelivery" description="cashondelivery" />
                      </span>
                    </div>
                  );
                case PAYMENT_METHOD.OXXOPAY:
                  return (
                    <div className="cart-order-summary__review-info card-wrapper">
                      <PaymentIcon className="card" iconName="oxxo" />
                      <span className="card-name">
                        <FormattedMessage id="cart.summary.paymentmethod.oxxopay" description="OXXO pay" />
                      </span>
                    </div>
                  );
                case PAYMENT_METHOD.ZEROVALUE:
                  return (
                    <div className="cart-order-summary__review-info card-wrapper">
                      <FormattedMessage id="checkout.billing.zeroValue.title" description="Payment description" />
                    </div>
                  );
                default:
                  return (
                    <div>
                      <div className="cart-order-summary__review-info card-wrapper">
                        <PaymentIcon className="card" iconName={getCardProperty(cardDetailsData, properties, 'cardType')} />
                        <span className="card-name">{getCardProperty(cardDetailsData, properties, 'cardType')} </span>
                        <span>****</span>
                        <span> {getLastNumbers(
                          getCardProperty(cardDetailsData, properties, 'cardNumber'),
                          NUMBER_OF_CHART_TO_DISPLAY
                        )}
                        </span>
                      </div>
                      <div className="cart-order-summary__review-info">
                        <span>{getFullAddress(paymentInfo.billingAddress)}</span>
                      </div>
                    </div>
                  );
              }
})()
          }
        </div>
        { children }
      </div>
    </div>
  );
};

PaymentMethod.propTypes = {
  paymentInfo: PropTypes.object.isRequired,
  cardDetails: PropTypes.object,
  children: PropTypes.any
};

export default PaymentMethod;
