import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import NameFormGroup from 'app/formComponents/nameFormGroup/nameFormGroupReducer';
import customEvents from 'app/customEvents/customEventsMiddleware';
import PasswordTextInput from 'app/formComponents/passwordTextInput/passwordTextInputReducer';
import { FormReducer as Forms } from 'react-forms';
import GlobalMessages from 'app/globalMessages/globalMessagesReducer';
import AuthForm from './authFormReducer';
import CountrySelector from 'app/formComponents/countrySelector/countrySelectorReducer';
import RegionSelector from 'app/formComponents/regionSelector/regionSelectorReducer';
import PostCodeSuggestion from 'app/formComponents/postCodeSuggestion/postCodeSuggestionReducer';
import { MemberIdLookupReducer } from 'app/auth/layouts/registration/components/memberIdFormGroup';
import { miniCart, totalEntries } from 'app/orderPlacement/miniCart/miniCartReducer';

const authAppReducers = combineReducers({
  GlobalMessages,
  Forms,
  NameFormGroup,
  PasswordTextInput,
  AuthForm,
  CountrySelector,
  RegionSelector,
  PostCodeSuggestion,
  totalEntries,
  miniCart,
  MemberIdLookup: MemberIdLookupReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  authAppReducers,
  composeEnhancers(
    applyMiddleware(thunk, customEvents)
  )
);

export default store;
