import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormActions, FormError, FormGroup } from 'react-forms';
import * as RegionSelectorActions from './regionSelectorActions';
import OptionIntl from '../optionIntl/optionIntl';

class RegionSelector extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      formName, setInputValue, getRegions, regionsUrl, currentSelectedCountry, selectedRegion
    } = nextProps;
    if (currentSelectedCountry && this.props.currentSelectedCountry !== currentSelectedCountry) {
      getRegions(regionsUrl, currentSelectedCountry);
    }

    if (selectedRegion !== this.props.selectedRegion) {
      setInputValue(formName, {
        selectedRegion
      });
    }
  }

  render() {
    const {
      regions,
      formName,
      handleValidForm,
      handleInvalidForm,
      validation,
      selectedRegion,
      regionName,
      displayCityColoniaPostCodeFields,
      usePlaceholder
    } = this.props;
    const regionsUnavailable =
      regions.length === 0
      || regions === null
      || regions[0].isocode === null;

    return (
      <div className={(regionsUnavailable) ? 'display-none' : ''}>
        <div className="row">
          <div className="column large-12">
            <FormGroup
              formName={formName}
              handleValidForm={handleValidForm}
              handleInvalidForm={handleInvalidForm}
              validation={validation}
            >
              <div className="form-input">
                {
                  !usePlaceholder &&
                  <label htmlFor={regionName}>
                    <FormattedMessage id="security.address.state.label" />
                  </label>
                }
                <select className="region-selector__select" name={regionName} id={regionName} value={selectedRegion !== null ? selectedRegion : ''}>
                  <OptionIntl value="" id={usePlaceholder ? 'security.address.state.label' : 'addressForm.country.default'} />
                  {regions.map((region) =>
                    <option key={`region-${region.isocode}_${region.name}`} value={region.isocode}>{region.name}</option>
                    )}
                </select>
                <FormError forInput={regionName} />
              </div>
              {
                displayCityColoniaPostCodeFields ?
                  <div>
                    <div className="form-input">
                      <label className="form-input__label" htmlFor="city">
                        <FormattedMessage id="security.address.city.label" />
                      </label>
                      <input className="form-input region-selector__city" type="text" name="city" id="city" value="" required />
                      <FormError forInput="city" />
                    </div>
                    <div className="form-input">
                      <label className="form-input__label" htmlFor="colonia">
                        <FormattedMessage id="security.address.district.label" />
                      </label>
                      <input className="form-input region-selector__colonia" type="text" name="colonia" id="colonia" value="" required />
                      <FormError forInput="colonia" />
                    </div>
                    <div className="form-input form-input__postal-code">
                      <label className="form-input__label" htmlFor="postalCode">
                        <FormattedMessage id="security.address.postcode.label" />
                      </label>
                      <input maxLength="50" className="form-input region-selector__postalcode" type="text" name="postalCode" id="postalCode" value="" required />
                      <FormError forInput="postalCode" />
                    </div>
                  </div>
                  : ''
                }
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

RegionSelector.defaultProps = {
  regionName: 'region'
};

RegionSelector.propTypes = {
  regionName: PropTypes.string,
  regionsUrl: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRegions: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  currentSelectedCountry: PropTypes.string,
  formName: PropTypes.string.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  selectedRegion: PropTypes.string,
  displayCityColoniaPostCodeFields: PropTypes.bool,
  usePlaceholder: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const countrySelector = state.Forms[props.countryFormName] || { values: {} };
  const currentSelectedCountry = (countrySelector.values.country && countrySelector.values.country.isocode) ?
    countrySelector.values.country.isocode :
    countrySelector.values.country;
  return ({
    regions: state.RegionSelector.regions,
    currentSelectedCountry
  });
};

export default connect(mapStateToProps, {
  ...RegionSelectorActions,
  ...FormActions
})(RegionSelector);
