
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup } from 'react-forms';
import PasswordTextInput from 'app/formComponents/passwordTextInput/passwordTextInput';
import mediaQueries from 'app/utils/mediaQueries';
import { ANIMATION_TIME, TIME_FOR_CLOSING_KEYBOARD } from '../formComponentsConstants';
import CellPhoneNumber from 'app/formComponents/phoneNumber/cellPhoneNumber';

export default class UsernameAndPasswordFormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordMasked: true,
      emailInputInUse: false,
      passwordInputInUse: false,
      confirmPasswordInputInUse: false,
    };

    this.scrollTop = 0;
    this.togglePasswordMask = this.togglePasswordMask.bind(this);
    this.setNegativeHtmlMarginTop = this.setNegativeHtmlMarginTop.bind(this);
    this.resetHtmlMarginTopAfterKeyboardIsHidden = this.resetHtmlMarginTopAfterKeyboardIsHidden.bind(this);
    this.selectInputInUseHandler = this.selectInputInUseHandler.bind(this);
    this.sectionInputInUseHandler = this.sectionInputInUseHandler.bind(this);
  }

  componentDidMount() {
    this.handleChromeVirutalKeyboardBug();
  }

  componentWillUnmount() {
    if (this.isAndroid && this.isMountedInlargeUp && this.isChrome) {
      this.$authContainerInputs.forEach((element) => {
        element.removeEventListener('focus', this.setNegativeHtmlMarginTop);
        element.removeEventListener('blur', this.resetHtmlMarginTopAfterKeyboardIsHidden);
      });
    }
  }

  getNotice() { /* eslint-disable-line class-methods-use-this */
    return (
      <span className="form-input__input-hint">
        <FormattedMessage id="security.ui.register.password.requirements" />{' '}<br />
        <FormattedMessage id="security.ui.register.password.notice" />{' '}
        <b><FormattedMessage id="security.ui.register.password.website" /></b>.
      </span>
    );
  }

  setNegativeHtmlMarginTop() {
    const { $html } = this;
    clearTimeout(this.keyboardTimer);
    if ($html.style['margin-top'][0] !== '-') {
      this.scrollTop = window.pageYOffset;
      $html.style['margin-top'] = `${-this.scrollTop}px`;
      window.scrollTo(0, 0);
    }
  }

  selectInputInUseHandler(input, value, event) {
    let inputStateHandler = {
      ...this.state
    };
    switch (input) {
      case 'email':
        inputStateHandler = {
          ...inputStateHandler,
          emailInputInUse: value,
        };
        break;
      case 'password':
        inputStateHandler = {
          ...inputStateHandler,
          passwordInputInUse: value,
        };
        if (this.props.setPasswordValue) {
          this.props.setPasswordValue(event);
        }
        break;
      default:
        return inputStateHandler;
    }
    return inputStateHandler;
  }

  sectionInputInUseHandler(event, input) {
    event.preventDefault();
    let inputStateHandler;
    if (event.target.value.length > 0) {
      inputStateHandler = this.selectInputInUseHandler(input, true, event.target.value);
    } else {
      inputStateHandler = this.selectInputInUseHandler(input, false, event.target.value);
    }
    this.setState({
      emailInputInUse: inputStateHandler.emailInputInUse,
      passwordInputInUse: inputStateHandler.passwordInputInUse,
    });
  }

  togglePasswordMask() {
    this.setState({
      isPasswordMasked: !this.state.isPasswordMasked
    });
  }

  resetHtmlMarginTopAfterKeyboardIsHidden() {
    const { $html, scrollTop } = this;
    this.keyboardTimer = setTimeout(() => {
      $html.style['margin-top'] = 0;
      window.scrollTo(0, scrollTop);
    }, TIME_FOR_CLOSING_KEYBOARD);
  }

  handleChromeVirutalKeyboardBug() {
    this.isAndroid = /Android/i.test(navigator.userAgent);
    this.isMountedInlargeUp = mediaQueries.is_large_up();
    this.isChrome = window.chrome !== undefined;
    if (this.isAndroid && this.isMountedInlargeUp && this.isChrome) {
      this.$authContainerInputs = document.querySelectorAll('.auth-container input');
      this.$html = document.getElementsByTagName('html')[0]; // eslint-disable-line
      setTimeout(() => {
        this.$authContainerInputs.forEach((element) => {
          element.addEventListener('focus', this.setNegativeHtmlMarginTop);
        });
        this.$authContainerInputs.forEach((element) => {
          element.addEventListener('blur', this.resetHtmlMarginTopAfterKeyboardIsHidden);
        });
      }, ANIMATION_TIME);
    }
  }

  render() {
    const {
      isInputInUse, inputInUseHandler, formName, placeholders, displayNotice, lookupMemberIdOnEmailBlur, memberIdLookup, ...handlersAndValidation
    } = this.props;
    return (
      <FormGroup
        formName={formName}
        className="form-group--no-margin"
        {...handlersAndValidation}
      >
        <div className="row">
          <div className={this.props.cellPhoneComponent ? 'columns large-6 medium-6 small-12' : 'columns large-12 medium-12 small-12'}>
            {this.props.cellPhoneComponent ? (<div className=" icon-mail register-icon" style={{ fontSize: '1.1em', top: '14px', left: '-17px' }} />) : ''}
            <div className={this.props.regularRegisterUserForm ? 'form-input-small' : 'form-input'}>
              <div className="form-input__input-used">
                {this.state.emailInputInUse ? <label htmlFor="username" className="mini-label"><FormattedMessage id="security.ui.auth.username" description="Email" /></label> : ''}
                <input
                  className="form-input__input"
                  type="email"
                  id="username"
                  name="username"
                  value=""
                  placeholder={`${placeholders ? placeholders.email : '*Correo Electrónico'}`}
                  onBlur={lookupMemberIdOnEmailBlur ? memberIdLookup : null}
                  onChange={(event) => this.sectionInputInUseHandler(event, 'email')}
                />
              </div>
              <FormError forInput="username" />
            </div>
          </div>
          {this.props.cellPhoneComponent ?
            <CellPhoneNumber
              cellPhoneComponent
              isInputInUse={isInputInUse}
              inputInUseHandler={inputInUseHandler}
              formName={this.props.formNamePhone}
              usePlaceholder
              {...handlersAndValidation}
            /> : ''}
        </div>
        <div className="row">
          <div className="columns large-12">
            <div className="form-input" style={this.props.regularRegisterUserForm ? { marginBottom: '0' } : {}}>
              <div className="form-input__input-used">
                {this.state.passwordInputInUse ? <label htmlFor="password" className="mini-label"><FormattedMessage id="security.ui.auth.password" description="Password" /></label> : ''}
                <PasswordTextInput>
                  <input
                    className="form-input__input"
                    id="password"
                    type="password"
                    name="password"
                    value=""
                    placeholder={`${placeholders ? placeholders.password : '*Contraseña'}`}
                    onChange={(event) => this.sectionInputInUseHandler(event, 'password')}
                  />
                </PasswordTextInput>
              </div>
              <FormError forInput="password" />
              {displayNotice ? this.getNotice() : ''}
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

UsernameAndPasswordFormGroup.propTypes = {
  formName: PropTypes.string.isRequired,
  placeholders: PropTypes.object,
  displayNotice: PropTypes.bool,
  lookupMemberIdOnEmailBlur: PropTypes.bool,
  memberIdLookup: PropTypes.func,
  isInputInUse: PropTypes.func,
  inputInUseHandler: PropTypes.func,
  cellPhoneComponent: PropTypes.bool,
  regularRegisterUserForm: PropTypes.bool,
  formNamePhone: PropTypes.any,
  setPasswordValue: PropTypes.any,
};
