import { range } from 'lodash';

export const getMaxCalendarDays = () => {
  const start = 1;
  const end = 32;
  return range(start, end);
};

export const getMonthNames = () => [
  'months.january',
  'months.february',
  'months.march',
  'months.april',
  'months.may',
  'months.june',
  'months.july',
  'months.august',
  'months.september',
  'months.october',
  'months.november',
  'months.december'
];

export const getYears = (minAge, maxAge) => {
  const minDate = (new Date().getFullYear()) - minAge;
  const maxDate = (new Date().getFullYear()) - maxAge;
  return range(minDate, maxDate);
};

const padLength = 2;
const padSingleDigit = (number, length = padLength) =>
  (`${number}`.length < length ? `0${number}` : number);

export const getDOBIfComplete = (dateObj = {}) => {
  const {
    day = '',
    month = '',
    year = ''
  } = dateObj;

  const hasDate = (year !== '' && month !== '' && day !== '');
  if (hasDate) {
    return `${padSingleDigit(year)}-${padSingleDigit(month)}-${padSingleDigit(day)}`;
  }

  return undefined;
};

export const getDayMonthYearObject = (dob) =>
  dob.split('-')
    .reduce(
      (dateObj, current, index) => ({
        ...dateObj,
        [Object.keys(dateObj)[index]]: parseInt(current, 10)
      }),
      { year: '', month: '', day: '' }
    );

