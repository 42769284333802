import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  FormError,
  FormGroup
} from 'react-forms';

class NameFormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstNameInputUsed: false,
      secondNameInputUsed: false,
      lastNameInputUsed: false,
      secondLastNameInputUsed: false,
    };
    this.nameInputInUseHandler = this.nameInputInUseHandler.bind(this);
    this.selectInputInUseHandler = this.selectInputInUseHandler.bind(this);
  }


  selectInputInUseHandler(input, value) {
    let inputStateHandler = {
      ...this.state
    };
    switch (input) {
      case 'firstName':
        inputStateHandler = {
          ...inputStateHandler,
          firstNameInputUsed: value,
        };
        break;
      case 'secondName':
        inputStateHandler = {
          ...inputStateHandler,
          secondNameInputUsed: value,
        };
        break;
      case 'lastName':
        inputStateHandler = {
          ...inputStateHandler,
          lastNameInputUsed: value,
        };
        break;
      case 'secondLastName':
        inputStateHandler = {
          ...inputStateHandler,
          secondLastNameInputUsed: value,
        };
        break;
      default:
        return inputStateHandler;
    }
    return inputStateHandler;
  }

  nameInputInUseHandler(event, input) {
    event.preventDefault();
    let inputStateHandler;
    if (event.target.value.length > 0) {
      inputStateHandler = this.selectInputInUseHandler(input, true);
    } else {
      inputStateHandler = this.selectInputInUseHandler(input, false);
    }
    this.setState({
      firstNameInputUsed: inputStateHandler.firstNameInputUsed,
      secondNameInputUsed: inputStateHandler.secondNameInputUsed,
      lastNameInputUsed: inputStateHandler.lastNameInputUsed,
      secondLastNameInputUsed: inputStateHandler.secondLastNameInputUsed,
    });
  }

  render() {
    const {
      validation, ...otherProps
    } = this.props;

    return (
      <FormGroup
        validation={validation}
        {...otherProps}
      >
        {this.props.regularRegisterUserForm
          ?
          (
            <div className="row">
              <div className="columns large medium-12">
                <div className="row">
                  <div className="columns medium-6">
                    <div className="icon-persona register-icon" style={{ fontSize: '1.1em', top: '14px', left: '-17px' }} />
                    <div className="form-input-small">
                      <div className="form-input__input-used">
                        {this.state.firstNameInputUsed ? <label htmlFor="firstName" className="mini-label"> <FormattedMessage id="security.ui.auth.firstName" /></label> : ''}
                        <input key="name-input" maxLength="50" className="no-border-input" type="text" id="firstName" name="firstName" value="" required placeholder="*Nombre" onChange={(event) => this.nameInputInUseHandler(event, 'firstName')} />
                      </div>
                      <FormError forInput="firstName" />
                    </div>
                  </div>
                  <div className="columns medium-6">
                    <div className="form-input">
                      <div className="form-input__input-used">
                        {this.state.lastNameInputUsed ? <label htmlFor="lastName" className="mini-label"><FormattedMessage id="security.ui.auth.lastName" /></label> : ''}
                        <input maxLength="50" className="no-border-input" type="text" id="lastName" name="lastName" value="" required onChange={(event) => this.nameInputInUseHandler(event, 'lastName')} placeholder="*Apellidos" />
                      </div>
                      <FormError forInput="lastName" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          :
          (
            <div className="row">
              <div className="columns medium-12">
                <div className="row">
                  <div className="columns medium-6">
                    <div className="form-input">
                      <div className="form-input__input-used">
                        {this.state.firstNameInputUsed ? <label htmlFor="firstName" className="mini-label"><FormattedMessage id="security.ui.auth.firstName" /></label> : ''}
                        <input key="name-input" maxLength="50" className="no-border-input" type="text" id="firstName" name="firstName" value="" required placeholder="*Primer nombre" onChange={(event) => this.nameInputInUseHandler(event, 'firstName')} />
                      </div>
                      <FormError forInput="firstName" />
                    </div>
                  </div>
                  <div className="columns medium-6">
                    <div className="form-input">
                      <div className="form-input__input-used">
                        {this.state.secondNameInputUsed ? <label htmlFor="secondFirstName" className="mini-label"> <FormattedMessage id="security.ui.auth.secondFirstName" /></label> : ''}
                        <input maxLength="50" className="form-input__input" type="text" id="secondFirstName" name="secondFirstName" value="" onChange={(event) => this.nameInputInUseHandler(event, 'secondName')} placeholder="*Segundo nombre" required />
                      </div>
                      <FormError forInput="secondFirstName" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns medium-12">
                <div className="form-input">
                  <div className="form-input__input-used">
                    {this.state.lastNameInputUsed ? <label htmlFor="lastName" className="mini-label"><FormattedMessage id="security.ui.auth.lastName" /></label> : ''}
                    <input maxLength="50" className="no-border-input" type="text" id="lastName" name="lastName" value="" required onChange={(event) => this.nameInputInUseHandler(event, 'lastName')} placeholder="*Apellido paterno" />
                  </div>
                  <FormError forInput="lastName" />
                </div>
              </div>
              <div className="columns medium-12">
                <div className="form-input">
                  <div className="form-input__input-used">
                    {this.state.secondLastNameInputUsed ? <label className="mini-label" htmlFor="secondLastName"><FormattedMessage id="security.ui.auth.secondLastName" /></label> : ''}
                    <input placeholder="*Apellido materno" maxLength="50" className="form-input__input" type="text" id="secondLastName" name="secondLastName" value="" required onChange={(event) => this.nameInputInUseHandler(event, 'secondLastName')} />
                  </div>
                  <FormError forInput="secondLastName" />
                </div>
              </div>
            </div>
          )
        }
      </FormGroup>
    );
  }
}

NameFormGroup.propTypes = {
  validation: PropTypes.object.isRequired,
  regularRegisterUserForm: PropTypes.bool,
};

export default NameFormGroup;
