import { SET_AVAILABLE_REGIONS, SET_CURRENT_REGION } from './regionSelectorConstants';

const initialState = {
  regions: [],
  currentSelectedCountry: '',
  currentlySelectedRegion: ''
};

const regionSelector = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_REGIONS:
      return {
        ...state,
        regions: action.regions
      };
    case SET_CURRENT_REGION:
      return {
        ...state,
        currentlySelectedRegion: action.currentlySelectedRegion
      };
    default:
      return state;
  }
};

export default regionSelector;
