import React from 'react';
import PropTypes from 'prop-types';
import CountrySelector from 'app/formComponents/countrySelector/countrySelector';
import Name from 'app/formComponents/nameFormGroup/nameFormGroup';
import AddressFormGroup from 'app/formComponents/addressFormGroup/addressFormGroup';
import RegionSelector from 'app/formComponents/regionSelector/regionSelector';
import PhoneNumber from 'app/formComponents/phoneNumber/phoneNumber';
import AddressFormModal from '../addressFormModal/addressFormModal';
import PostcodeLookup from '../postcodeLookup/postcodeLookup';

const { addressValidation } = window.inlineGlobalConfig;

const AddressForm = ({
  getCountriesUrl,
  getRegionsUrl,
  hidePhoneNumber,
  formGroups,
  selectedRegion,

  modalToggle,
  isModalOpen,
  customParentClass,
  titleMsgID,

  entries,
  validatedForm,
  onValidationComplete,

  showPostcodeValidationForm,
  showPostcodeTryAnotherMsg,
  showPostcodeSelectAddress,
  postcodeEntries,
  postcodeFormGroups,
  onPostcodeValidationComplete,
  togglePostcodeValidation,
  togglePostcodeSelectAddress,
  isPredefinedAddress,
  hasReference
}) => {
  const handlersAndValidation = {
    handleValidForm: formGroups.resolve,
    handleInvalidForm: formGroups.reject,
    validation: addressValidation
  };

  const postcodeHandlersAndValidation = {
    handleValidForm: postcodeFormGroups.resolve,
    handleInvalidForm: postcodeFormGroups.reject,
    validation: addressValidation
  };

  return (
    <div className="address-form">
      <div className="country-selector__wrapper">
        <CountrySelector
          countriesUrl={getCountriesUrl}
          formName={formGroups.getName('countrySelector')}
          {...handlersAndValidation}
        />
      </div>

      <div className="address-name__wrapper">
        <Name
          formName={formGroups.getName('name')}
          {...handlersAndValidation}
        />
      </div>

      {showPostcodeValidationForm ?
        <div className="address-group__wrapper">
          <PostcodeLookup
            formName={postcodeFormGroups.getName('postcodeValidation')}
            {...postcodeHandlersAndValidation}

            showPostcodeValidationForm={showPostcodeValidationForm}
            showPostcodeTryAnotherMsg={showPostcodeTryAnotherMsg}
            showPostcodeSelectAddress={showPostcodeSelectAddress}
            postcodeEntries={postcodeEntries}
            togglePostcodeSelectAddress={togglePostcodeSelectAddress}
            onPostcodeValidationComplete={onPostcodeValidationComplete}
          />
        </div> :
        <div>
          <hr />

          <div className="region-selector__wrapper">
            <RegionSelector
              regionsUrl={getRegionsUrl}
              formName={formGroups.getName('addressRegion')}
              countryFormName={formGroups.getName('countrySelector')}
              selectedRegion={selectedRegion}
              displayCityColoniaPostCodeFields
              {...handlersAndValidation}
            />
          </div>

          <hr />

          <div className="address-group__wrapper">
            <AddressFormGroup
              isPredefinedAddress={isPredefinedAddress}
              formName={formGroups.getName('addressFormGroup')}
              showPostcodeTryAnotherMsg={showPostcodeTryAnotherMsg}
              togglePostcodeValidation={togglePostcodeValidation}
              hasReference={hasReference}
              {...handlersAndValidation}
            />
          </div>

          {
            !hidePhoneNumber
            &&
            <div className="phone-number__wrapper">
              <PhoneNumber
                formName={formGroups.getName('phoneNumber')}
                {...handlersAndValidation}
              />
            </div>
          }

          <hr />

          <AddressFormModal
            modalToggle={modalToggle}
            isModalOpen={isModalOpen}
            customParentClass={customParentClass}
            titleMsgID={titleMsgID}

            entries={entries}
            validatedForm={validatedForm}
            onValidationComplete={onValidationComplete}
          />
        </div>
      }


    </div>
  );
};

AddressForm.propTypes = {
  getCountriesUrl: PropTypes.string.isRequired,
  getRegionsUrl: PropTypes.string.isRequired,
  formGroups: PropTypes.object.isRequired,
  hidePhoneNumber: PropTypes.bool,
  selectedRegion: PropTypes.string,
  hasReference: PropTypes.bool,

  // Modal related props
  modalToggle: PropTypes.func,
  isModalOpen: PropTypes.bool,
  customParentClass: PropTypes.string,
  titleMsgID: PropTypes.string,

  // Validation related props
  entries: PropTypes.array,
  validatedForm: PropTypes.object,
  onValidationComplete: PropTypes.func,

  // Postcode lookup related properties
  showPostcodeValidationForm: PropTypes.bool.isRequired,
  showPostcodeTryAnotherMsg: PropTypes.bool.isRequired,
  showPostcodeSelectAddress: PropTypes.bool,
  postcodeEntries: PropTypes.array,
  postcodeFormGroups: PropTypes.object,
  togglePostcodeValidation: PropTypes.func,
  togglePostcodeSelectAddress: PropTypes.func,
  onPostcodeValidationComplete: PropTypes.func,
  isPredefinedAddress: PropTypes.bool
};

export default AddressForm;
