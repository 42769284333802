import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { getFullAddress, getPhoneLine } from 'app/utils/addressHelpers';

const DeliveryAddress = ({ countries, deliveryAddress, children }) => (
  <div className="cart-order-summary__delivery-address-container">
    <div className="row">
      <div className="columns small-7">
        <div className="cart-order-summary__section-title">
          <h5>
            <FormattedMessage id="checkout.review.delivery.address.title" description="Delivery address" />
          </h5>
        </div>
        <div className="cart-order-summary__review-info">
          {getFullAddress(deliveryAddress, countries)}
          {getPhoneLine((deliveryAddress.phone) ? deliveryAddress.phone : '', (deliveryAddress.cellPhoneNumber) ? deliveryAddress.cellPhoneNumber : '')}
        </div>
      </div>
      { children }
    </div>
  </div>
);

DeliveryAddress.propTypes = {
  deliveryAddress: PropTypes.object.isRequired,
  children: PropTypes.object,
  countries: PropTypes.array.isRequired,
};

export default DeliveryAddress;
