import { combineReducers } from 'redux';
import { CitySelectorReducer } from './components/citySelector';
import { ColoniaSelectorReducer } from './components/coloniaSelector';
import { PostalCodeReducer } from './components/postalCode';
import { RegionSelectorReducer } from './components/regionSelector';

const PostCodeSuggestion = combineReducers({
  CitySelector: CitySelectorReducer,
  ColoniaSelector: ColoniaSelectorReducer,
  PostalCodeSelector: PostalCodeReducer,
  RegionSelector: RegionSelectorReducer,
});

export default PostCodeSuggestion;
