import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const DeliveryMethod = ({ deliveryMode }) => (
  <div className="cart-order-summary__delivery-method-container">
    <div className="row">
      <div className="columns small-8">
        <div className="cart-order-summary__section-title">
          <h5>
            <FormattedMessage id="checkout.review.delivery.method.title" description="Delivery mode" />
          </h5>
        </div>
      </div>
      <div className="columns small-4">
        <div className="cart-order-summary__review-info right">
          <span>{deliveryMode.name}</span>
        </div>
      </div>
    </div>
  </div>
);

DeliveryMethod.propTypes = {
  deliveryMode: PropTypes.object.isRequired
};

export default DeliveryMethod;
