import * as CONST from './addressBookConstants';

const initialState = {
  addresses: []
};

const addressBookReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.GET_ADDRESS_BOOK:
      return {
        addresses: action.addresses
      };
    default:
      return state;
  }
};

export default addressBookReducer;
