import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup } from 'react-forms';
import scrollToTopHOC from '../scrollToTop/scrollToTopHOC';

const EmailForm = ({
  name,
  formName,
  email,
  emailTxtID,
  submitTxtID,
  hintTxtID,
  backTxtID,
  backLink,
  isBackLinkExternal,
  disableSubmit,
  onSubmit,
  children,
  forgotPasswordTitle,
  forgotPasswordButtonClass,
  backButtonClass,
  ...handlersAndValidation
}) => (
  <div className="auth-form">
    <form name={name} onSubmit={onSubmit} noValidate>
      <FormGroup
        formName={formName}
        {...handlersAndValidation}
      >
        <div className="form-input">
          <label className={`${forgotPasswordTitle} form-input__label`} htmlFor="email">
            <FormattedMessage id={emailTxtID} description="email" />
          </label>
          <input className="form-input__input" type="email" id="email" name="email" placeholder="Correo electrónico*" value={email} required />
          <FormError forInput="email" />
        </div>
        {children}
        <button className={`${forgotPasswordButtonClass} button button--stretch`} disabled={disableSubmit}>
          <FormattedMessage id={submitTxtID} description="Submit button" />
        </button>
        <div className="auth-form__back text-center">
          {
            hintTxtID &&
            <p>
              <FormattedMessage id={hintTxtID} description="hint copy" />
            </p>
          }
          {/* <LinkExternal to={backLink} isExternal={isBackLinkExternal} backButtonClass={backButtonClass}>
            <FormattedMessage id={backTxtID} description="Back" />
          </LinkExternal> */}
        </div>
      </FormGroup>
    </form>
  </div>
);

EmailForm.defaultProps = {
  email: '',
  isBackLinkExternal: false,
  disableSubmit: false
};

EmailForm.propTypes = {
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  emailTxtID: PropTypes.string.isRequired,
  submitTxtID: PropTypes.string.isRequired,
  hintTxtID: PropTypes.string,
  backTxtID: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  isBackLinkExternal: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  children: PropTypes.any,
  forgotPasswordTitle: PropTypes.string,
  forgotPasswordButtonClass: PropTypes.string,
  backButtonClass: PropTypes.string,
};

export default scrollToTopHOC(EmailForm);
