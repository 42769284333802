import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import ModalWrapper from 'app/modal/modalWrapper';

/* eslint-disable react/no-array-index-key */

class AddressFormModal extends Component {
  constructor() {
    super();

    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.passAddressToValidation = this.passAddressToValidation.bind(this);

    this.state = {
      addressList: null,
      selection: null,
      selectedAddress: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.entries, nextProps.entries)) {
      const addressList = nextProps.entries ?
        Object.values(nextProps.entries).map((address, id) => <option key={id}>{address.addressString}</option>) :
        null;

      this.setState({
        addressList
      });
    }
  }

  handleAddressSelect(e) {
    this.setState({
      selection: e.target.value
    }, () => {
      const selectedAddress = this.props.entries.filter(address => {
        const addressStringReplaced = address.addressString.replace(/\s\s+/g, ' ');
        return addressStringReplaced === this.state.selection;
      })[0];

      this.setState({
        selectedAddress
      });
    });
  }

  passAddressToValidation() {
    const notDefaultValue = this.state.selectedAddress !== null && this.state.selectedAddress !== undefined;

    if (this.state.addressList && this.state.addressList.length > 0 && notDefaultValue) {
      this.props.onValidationComplete(this.state.selectedAddress);
    } else if (this.props.validatedForm !== null) {
      this.props.onValidationComplete(this.props.validatedForm);
    }

    this.props.modalToggle();
    this.setState({
      selection: null,
      selectedAddress: null
    });
  }

  render() {
    if (this.props.isModalOpen === false) {
      return null;
    }

    const oneLineAddress = this.props.validatedForm ?
      `${this.props.validatedForm.line1}, 
      ${this.props.validatedForm.line2}, 
      ${this.props.validatedForm.city}, 
      ${this.props.validatedForm.country}, 
      ${this.props.validatedForm.postalCode}` : '';

    return (
      <ModalWrapper
        {...this.props}
        maxWidth={700}
      >
        <p>
          <FormattedMessage id="security.address.modal.addressValidation.justification" />
        </p>

        {this.state.addressList && this.state.addressList.length > 0 ?
          <select className="address-form-modal__select" onChange={this.handleAddressSelect} defaultValue="default">
            <option value="default">{this.context.intl.formatMessage({ id: 'security.address.modal.addressValidation.select.default' })}</option>
            {this.state.addressList}
          </select> :
          <label htmlFor="oneline-address">
            <span className="form-checkbox">
              <input id="oneline-address" className="form-checkbox__input" type="checkbox" checked="checked" readOnly />
              <span className="form-checkbox__checkbox" />
            </span>
            <span className="form-checkbox__label">{oneLineAddress}</span>
          </label>
        }


        <button className="button address-form-modal__use-address-btn" onClick={this.passAddressToValidation}>
          <FormattedMessage id="security.address.modal.addressValidation.useButtonOption.label" />
        </button>

        <p className="address-form-modal__edit">
          <FormattedMessage id="security.address.modal.addressValidation.orWord" />
          {' '}
          <button className="button address-form-modal__edit-label" onClick={this.props.modalToggle}>
            <FormattedMessage id="security.address.modal.addressValidation.altButtonOption.label" />
          </button>
        </p>
      </ModalWrapper>
    );
  }
}

AddressFormModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalToggle: PropTypes.func.isRequired,
  entries: PropTypes.array,
  validatedForm: PropTypes.object,
  onValidationComplete: PropTypes.func
};

AddressFormModal.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default AddressFormModal;
