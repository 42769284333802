import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormConstants, FormError, FormGroup } from 'react-forms';
import { isEqual } from 'lodash';

class PostcodeLookup extends Component {
  constructor() {
    super();

    this.state = {
      addressList: null,
      selection: null,
      selectedAddress: null
    };

    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.passAddressToValidation = this.passAddressToValidation.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { postcodeEntries } = this.props;

    if (!isEqual(postcodeEntries, nextProps.postcodeEntries)) {
      const addressList = nextProps.postcodeEntries ?
        Object.values(nextProps.postcodeEntries).map(item => <option key={item.id}>{item.addressString}</option>) :
        null;

      this.setState({
        addressList
      });
    }
  }

  handleAddressSelect(e) {
    this.setState({
      selection: e.target.value
    }, () => {
      this.setState({
        selectedAddress: this.props.postcodeEntries.filter(address => address.addressString === this.state.selection)[0]
      });
    });
  }

  passAddressToValidation() {
    const { selectedAddress, selection } = this.state;
    const { onPostcodeValidationComplete } = this.props;

    const postcodeSelectAddressLabel = document.querySelector('label[for=postcodeSelect]');
    const postcodeSelectAddressField = document.querySelector('select[name=postcodeSelect]');
    const incorrectSelectAddressErrorMsg = document.querySelector('.address-form__incorrect-select-address-error');

    const addSelectAddressError = () => {
      postcodeSelectAddressLabel.classList.add(FormConstants.ERROR_INPUT_CLASS_NAME);
      postcodeSelectAddressField.classList.add(FormConstants.ERROR_INPUT_CLASS_NAME);
      incorrectSelectAddressErrorMsg.classList.add('display-block');
    };

    const removeSelectAddressError = () => {
      postcodeSelectAddressLabel.classList.remove(FormConstants.ERROR_INPUT_CLASS_NAME);
      postcodeSelectAddressField.classList.remove(FormConstants.ERROR_INPUT_CLASS_NAME);
      incorrectSelectAddressErrorMsg.classList.remove('display-block');
    };

    postcodeSelectAddressField.removeEventListener('change', removeSelectAddressError);

    if (selectedAddress !== null && selection !== 'default') {
      onPostcodeValidationComplete(selectedAddress);
      this.setState({
        selectedAddress: null
      });
    } else {
      addSelectAddressError();
      postcodeSelectAddressField.addEventListener('change', removeSelectAddressError);
    }
  }

  render() {
    const { showPostcodeValidationForm, showPostcodeSelectAddress, togglePostcodeSelectAddress } = this.props;

    if (!showPostcodeValidationForm) {
      return null;
    }

    return (
      <div className="address-form__postcode-lookup">
        {showPostcodeSelectAddress ?
          <div className="row">
            <div className="column small-12 medium-7 large-6">
              <div className="form-input address-form__postcode-select">
                <label className="form-input__label" htmlFor="postcodeSelect">
                  <FormattedMessage id="security.address.postcode.selectAddress.title" />
                </label>
                <select name="postcodeSelect" defaultValue="default" onChange={this.handleAddressSelect}>
                  <option value="default">
                    {this.context.intl.formatMessage({ id: 'security.address.postcode.selectAddress.defaultOption' })}
                  </option>
                  {this.state.addressList}
                </select>
                <span className="form-msg-error address-form__incorrect-select-address-error display-none">
                  <FormattedMessage id="security.address.postcode.selectAddress.error" />
                </span>
              </div>
            </div>
            <div className="column small-12 medium-5 large-6">
              <button type="button" className="button address-form__link-btn address-form__postcode-try-another-btn" onClick={togglePostcodeSelectAddress}>
                <FormattedMessage id="security.address.postcode.selectAddress.tryAnother" />
              </button>
            </div>
            <div className="column small-12">
              <button type="button" className="button address-form__btn address-form__postcode-continue-btn" onClick={this.passAddressToValidation}>
                <FormattedMessage id="security.address.postcode.continue.button.label" />
              </button>
            </div>
          </div> :
          <FormGroup
            {...this.props}
          >
            <div className="row">
              <div className="column large-6">
                <div className="form-input">
                  <p aria-labelledby="addressLine1" className="form-input__label">
                    <FormattedMessage id="security.address.postcode.line1.title" />{' '}
                    <span className="optional-field">
                      (<FormattedMessage id="security.address.optionalField.label" />)
                    </span>
                  </p>
                  <input className="form-input" type="text" name="addressLine1" id="addressLine1" value="" />
                </div>

                <div className="form-input">
                  <div className="row">
                    <div className="columns small-6">
                      <label className="form-input__label" htmlFor="postalCode">
                        <FormattedMessage id="security.address.postcode.label" />
                      </label>
                      <input className="form-input address-form__postcode-input" type="text" name="postalCode" id="postalCode" value="" required />
                      <FormError forInput="postalCode" />
                      <span className="form-msg-error address-form__incorrect-postcode-error display-none">
                        <FormattedMessage id="security.address.postcode.incorrectPostcode.message" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormGroup>
        }
      </div>
    );
  }
}

PostcodeLookup.contextTypes = {
  intl: PropTypes.object.isRequired
};

PostcodeLookup.propTypes = {
  showPostcodeValidationForm: PropTypes.bool.isRequired,
  showPostcodeSelectAddress: PropTypes.bool.isRequired,
  togglePostcodeSelectAddress: PropTypes.func.isRequired,
  onPostcodeValidationComplete: PropTypes.func.isRequired,
  postcodeEntries: PropTypes.array
};

export default PostcodeLookup;
