import * as CONST from './authFormConstants';

const initialState = {
  migrationMsg: null,
  buttonDisabled: false
};

const AuthFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SET_MIGRATION_MSG: {
      if (!action.msg) {
        return {
          migrationMsg: null
        };
      }

      return {
        migrationMsg: action.msg.replace('{0}', '#/forgotPassword')
      };
    }

    case CONST.ENABLE_SUBMIT_BUTTON: {
      return { ...state, buttonDisabled: false };
    }

    case CONST.DISABLED_SUBMIT_BUTTON: {
      return { ...state, buttonDisabled: true };
    }

    default:
      return state;
  }
};

export default AuthFormReducer;
