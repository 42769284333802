import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup } from 'react-forms';

class AddressFormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streetNameInputInUse: false,
      streetNumberInputInUse: false,
      unitNumberInputInUse: false,
      landmarkNumberInputInUse: false,
    };
    this.selectInputInUseHandler = this.selectInputInUseHandler.bind(this);
    this.sectionInputInUseHandler = this.sectionInputInUseHandler.bind(this);
  }

  selectInputInUseHandler(input, value) {
    let inputStateHandler = {
      ...this.state
    };

    switch (input) {
      case 'street':
        inputStateHandler = {
          ...inputStateHandler,
          streetNameInputInUse: value,
        };
        break;
      case 'streetNumber':
        inputStateHandler = {
          ...inputStateHandler,
          streetNumberInputInUse: value,
        };
        break;
      case 'unitNumber':
        inputStateHandler = {
          ...inputStateHandler,
          unitNumberInputInUse: value,
        };
        break;
      case 'landmark':
        inputStateHandler = {
          ...inputStateHandler,
          landmarkInputInUse: value,
        };
        break;
      default:
        return inputStateHandler;
    }
    return inputStateHandler;
  }

  sectionInputInUseHandler(event, input) {
    event.preventDefault();
    let inputStateHandler;

    if (event.target.value.length > 0) {
      inputStateHandler = this.selectInputInUseHandler(input, true);
    } else {
      inputStateHandler = this.selectInputInUseHandler(input, false);
    }

    this.setState({
      streetNameInputInUse: inputStateHandler.streetNameInputInUse,
      streetNumberInputInUse: inputStateHandler.streetNumberInputInUse,
      unitNumberInputInUse: inputStateHandler.unitNumberInputInUse,
      landmarkInputInUse: inputStateHandler.landmarkInputInUse,
    });
  }

  render() {
    return (
      <FormGroup
        {...this.props}
      >
        <input type="hidden" name="id" value="" required />
        <div className="row">
          <div className="column large-12">
            <div className="row">
              <div className="column large-6 medium-6 small-12">
                <div className="form-input">
                  <div className="form-input__input-used">
                    {this.state.streetNameInputInUse ? <label htmlFor="line1" className="mini-label"><FormattedMessage id="security.address.streetName.label" /></label> : ''}
                    <input type="text" name="line1" id="line1" value="" required onChange={(event) => this.sectionInputInUseHandler(event, 'street')} placeholder="*Calle" />
                  </div>
                  <FormError forInput="line1" />
                </div>
              </div>
              <div className="columns large-3 medium-3 small-6">
                <div className="form-input">
                  <div className="form-input__input-used">
                    {this.state.streetNumberInputInUse ? <label htmlFor="line2" className="mini-label"><FormattedMessage id="security.address.streetNumber.label" /></label> : ''}
                    <input type="text" name="line2" id="line2" value="" required onChange={(event) => this.sectionInputInUseHandler(event, 'streetNumber')} placeholder="*Número exterior" />
                  </div>
                  <FormError forInput="line2" />
                </div>
              </div>
              <div className="columns large-3 medium-3 small-6">
                <div className="form-input">
                  <div className="form-input__input-used">
                    {this.state.unitNumberInputInUse ? <label htmlFor="unitNumber" className="mini-label"><FormattedMessage id="security.address.unitNumber.label" /></label> : ''}
                    <input type="text" name="unitNumber" id="unitNumber" value="" required onChange={(event) => this.sectionInputInUseHandler(event, 'unitNumber')} placeholder="Número interior" />
                  </div>
                  <FormError forInput="unitNumber" />
                </div>
              </div>
            </div>
            {
              this.props.hasReference &&
              <div className="form-input">
                <div className="form-input__input-used landmark-height">
                  {this.state.landmarkInputInUse ? <label htmlFor="landmark" className="mini-label"><FormattedMessage id="security.address.landmark.label" /></label> : ''}
                  <textarea
                    placeholder={this.props.inputPlaceholders ? `Referencia: (${this.props.inputPlaceholders.reference})` : 'Referencia'}
                    className={`${this.props.parentClassName}-reference`}
                    name="landmark"
                    id="landmark"
                    value=""
                    required
                    style={{ border: 'none' }}
                    onChange={(event) => this.sectionInputInUseHandler(event, 'landmark')}
                  />
                </div>
                <FormError forInput="landmark" />
              </div>
            }
          </div>
        </div>
      </FormGroup>
    );
  }
}

AddressFormGroup.defaultProps = {
  hasReference: true
};

AddressFormGroup.propTypes = {
  showPostcodeTryAnotherMsg: PropTypes.bool,
  togglePostcodeValidation: PropTypes.func,
  isPredefinedAddress: PropTypes.bool,
  hasReference: PropTypes.bool,
  parentClassName: PropTypes.string,
  inputPlaceholders: PropTypes.object,
  formName: PropTypes.string.isRequired,
};

export default AddressFormGroup;
