import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormActions } from 'react-forms';
import { CitySelector } from './components/citySelector';
import { ColoniaSelector } from './components/coloniaSelector';
import { PostalCodeSelector } from './components/postalCode';
import { RegionSelector } from './components/regionSelector';

const PostCodeSuggestionComponent = (props) => {
  let postCodeSuggestionJSX;

  if (props.checkoutAddressForm || props.regularRegisterUserForm) {
    postCodeSuggestionJSX = (
      <div>
        <div className="row">
          <div className="columns large-6 medium-6 small-12">
            <div className="register-icon" />
            <PostalCodeSelector
              regionFormName={props.regionFormName}
              cityFormName={props.cityFormName}
              formName={props.postalCodeFormName}
              handleValidForm={props.handleValidForm}
              handleInvalidForm={props.handleInvalidForm}
              validation={props.validation}
              selectedRegion={props.selectedRegion}
              selectedCity={props.selectedCity}
              selectedCityName={props.selectedCityName}
              selectedColonia={props.selectedColonia}
              selectedColoniaName={props.selectedColoniaName}
              postalCodesUrl={props.postalCodesUrl}
              suggestedPostalCode={props.suggestedPostalCode}
            />
          </div>
          <div className="columns large-6 medium-6 small-12">
            <RegionSelector
              formName={props.regionFormName}
              regionFormName={props.regionFormName}
              cityFormName={props.cityFormName}
              postalCodeFormName={props.postalCodeFormName}
              regionsUrl={props.regionsUrl}
              countryFormName={props.countryFormName}
              handleValidForm={props.handleValidForm}
              handleInvalidForm={props.handleInvalidForm}
              validation={props.validation}
              selectedRegion={props.selectedRegion}
              selectedRegionName=""
            />
          </div>
        </div>
        <div className="row">
          <div className="columns large-6 medium-6 small=6">
            <CitySelector
              formName={props.cityFormName}
              handleValidForm={props.handleValidForm}
              handleInvalidForm={props.handleInvalidForm}
              validation={props.validation}
              selectedRegion={props.selectedRegion}
              postalCodesUrl={props.postalCodesUrl}
            />
          </div>
          <div className="columns large-6 medium-6 small=6">

            <ColoniaSelector
              formName={props.coloniaFormName}
              postalCodeFormName={props.postalCodeFormName}
              handleValidForm={props.handleValidForm}
              handleInvalidForm={props.handleInvalidForm}
              validation={props.validation}
              selectedRegion={props.selectedRegion}
              selectedCity={props.selectedCity}
              selectedCityName={props.selectedCityName}
              selectedColonia={props.selectedColonia}
              selectedColoniaName={props.selectedColoniaName}
              postalCodesUrl={props.postalCodesUrl}
            />
          </div>

        </div>

      </div>

    );
  } else {
    postCodeSuggestionJSX = (
      <div className="row">
        <div className="column large-12 medium-12 small-12">

          <PostalCodeSelector
            regionFormName={props.regionFormName}
            cityFormName={props.cityFormName}
            formName={props.postalCodeFormName}
            handleValidForm={props.handleValidForm}
            handleInvalidForm={props.handleInvalidForm}
            validation={props.validation}
            selectedRegion={props.selectedRegion}
            selectedCity={props.selectedCity}
            selectedCityName={props.selectedCityName}
            selectedColonia={props.selectedColonia}
            selectedColoniaName={props.selectedColoniaName}
            postalCodesUrl={props.postalCodesUrl}
            suggestedPostalCode={props.suggestedPostalCode}
          />

          <RegionSelector
            formName={props.regionFormName}
            regionFormName={props.regionFormName}
            cityFormName={props.cityFormName}
            postalCodeFormName={props.postalCodeFormName}
            regionsUrl={props.regionsUrl}
            countryFormName={props.countryFormName}
            handleValidForm={props.handleValidForm}
            handleInvalidForm={props.handleInvalidForm}
            validation={props.validation}
            selectedRegion={props.selectedRegion}
            selectedRegionName=""
          />
          <CitySelector
            formName={props.cityFormName}
            handleValidForm={props.handleValidForm}
            handleInvalidForm={props.handleInvalidForm}
            validation={props.validation}
            selectedRegion={props.selectedRegion}
            postalCodesUrl={props.postalCodesUrl}
          />
          <ColoniaSelector
            formName={props.coloniaFormName}
            postalCodeFormName={props.postalCodeFormName}
            handleValidForm={props.handleValidForm}
            handleInvalidForm={props.handleInvalidForm}
            validation={props.validation}
            selectedRegion={props.selectedRegion}
            selectedCity={props.selectedCity}
            selectedCityName={props.selectedCityName}
            selectedColonia={props.selectedColonia}
            selectedColoniaName={props.selectedColoniaName}
            postalCodesUrl={props.postalCodesUrl}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="postcode-suggestion">
      {postCodeSuggestionJSX}
    </div>
  );
};

PostCodeSuggestionComponent.propTypes = {
  cityFormName: PropTypes.string.isRequired,
  coloniaFormName: PropTypes.string.isRequired,
  postalCodeFormName: PropTypes.string.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  selectedRegion: PropTypes.string,
  selectedCity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectedCityName: PropTypes.string,
  selectedColonia: PropTypes.string,
  selectedColoniaName: PropTypes.string,
  postalCodesUrl: PropTypes.string,
  regionsUrl: PropTypes.string,
  countryFormName: PropTypes.string,
  regionFormName: PropTypes.string,
  suggestedPostalCode: PropTypes.string,
  checkoutAddressForm: PropTypes.bool,
  regularRegisterUserForm: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const { regionFormName, cityFormName, coloniaFormName } = props;
  const regionSelector = state.Forms[regionFormName] || { values: {} };
  const selectedRegion = (regionSelector.values.region) ? regionSelector.values.region : null;
  const citySelector = state.Forms[cityFormName] || { values: {} };
  const selectedCity = (citySelector.values.city) ? citySelector.values.city : null;
  const coloniaSelector = state.Forms[coloniaFormName] || { values: {} };
  const selectedColonia = (coloniaSelector.values.colonia) ? coloniaSelector.values.colonia : null;
  const suggestedPostalCode = (coloniaSelector.values.postalCode) ? coloniaSelector.values.postalCode : null;
  return {
    selectedRegion,
    selectedCity,
    selectedCityName: state.PostCodeSuggestion.CitySelector.selectedCityName,
    selectedColonia,
    selectedColoniaName: state.PostCodeSuggestion.ColoniaSelector.selectedColoniaName,
    suggestedPostalCode
  };
};

export const PostCodeSuggestion = connect(mapStateToProps, {
  ...FormActions
})(PostCodeSuggestionComponent);
