import { hashHistory } from 'react-router';
import { FormActions } from 'react-forms';
import {
  FORGOT_PASSWORD_CONFIRMATION_URL,
  SET_MIGRATION_MSG,
  ENABLE_SUBMIT_BUTTON,
  DISABLED_SUBMIT_BUTTON,
  MEMBERID_LOOKUP,
  MEMBERID_LOOKUP_RESULT,
  MEMBERID_CHANGED,
  MEMBERID_LOOKUP_PREPOPULATED,
  MEMBERID_LOOKUP_URL,
  MEMBERID_LOOKUP_LOAD_STATE,
  MEMBERID_LOOKUP_LOAD_STATE_URL,
  MEMBERID_UPDATE_LOAD_STATE_URL,
  MEMBERID_LOOKUP_POST_CODE_STATE_URL,
  MEMBERID_LOOKUP_LOAD_STATE_RESULT,
  MEMBERID_OPTIONBOX_CHANGE,
  MEMBERID_LOOKUP_ERROR_LOAD_STATE_RESULT,
  MEMBER_UPDATE_STATE,
  MEMBERID_LOOKUP_UPDATE_STATE_RESULT,
  MEMBERID_LOOKUP_ERROR_UPDATE_STATE_RESULT,
  MEMBERID_LOOKUP_GET_STATE_RESULT,
  MEMBERID_LOOKUP_ERROR_GET_STATE_RESULT
} from './authFormConstants';
import { saveStorageData } from 'app/localStorage/localStorage';
import { setServerMsg, setServerMsgID } from 'app/globalMessages/globalMessagesActions';
import * as MSG from 'app/globalMessages/globalMessagesConstants';
import genericErrorHandler from 'app/utils/serverErrors';
import { ajaxRequest } from 'app/utils/helpers';

const { baseCartUrl, cleanCheckoutGuestUrl } = window.inlineCartConfiguration;

const {
  loginSubmitUrl,
  accountUrl,
  registrationSubmitUrl,
  forgotPasswordSubmitUrl,
  resetPasswordSubmitUrl,
  loginUrl,
} = window.inlineGlobalConfig;

export const setMigrationMsg = (msg) => ({
  type: SET_MIGRATION_MSG,
  msg
});

export const enabledSubmitButton = () => ({
  type: ENABLE_SUBMIT_BUTTON
});

export const disabledSubmitButtonDispatch = () => ({
  type: DISABLED_SUBMIT_BUTTON
});

export const cleanCheckoutGuest = () =>
  dispatch =>
    ajaxRequest('GET', cleanCheckoutGuestUrl).then(
      (res) => res,
      ({ status }) => genericErrorHandler(dispatch, status));

export const submitLogin = (formData) =>
  dispatch => {
    ajaxRequest('POST', loginSubmitUrl, formData).then(
      (res) => {
        dispatch(setMigrationMsg(null));
        if (res.success) {
          dispatch(disabledSubmitButtonDispatch());
          if (res.cartMerged) {
            saveStorageData(MSG.CART_MESSAGES_STORAGE, {
              validate: true,
              [MSG.TYPE_SUCCESS]: res.couponRemoved ? 'order.items.merged.cart.with.removed.coupon.message' : 'order.items.merged.cart.message'
            }, MSG.SESSION_STORAGE);
            window.location.href = baseCartUrl;
          } else {
            window.location.href = accountUrl;
          }
        } else {
          dispatch(enabledSubmitButton());
          if (res.exception === 'CredentialsExpiredException') {
            dispatch(setMigrationMsg(res.error));
            return res;
          }

          window.scrollTo(0, 0);
          dispatch(setServerMsg(res.error, MSG.TYPE_ERROR, true));
        }
        return res;
      },
      ({ status }) => genericErrorHandler(dispatch, status));
  };

export const submitRegistration = (formData, guid, { formGroupName, error }, formRedirectKey, setPaymentCallback) =>
  dispatch => {
    const formDataCopy = Object.assign({}, formData);
    if (formData.women || formData.men) {
      formDataCopy.newsletters = {
        men: formData.men,
        women: formData.women
      };
    }

    dispatch(disabledSubmitButtonDispatch());

    ajaxRequest('POST', guid ? `${registrationSubmitUrl}?guid=${guid}` : registrationSubmitUrl, formDataCopy).then(
      (res) => {
        dispatch(enabledSubmitButton());
        if (res.success) {
          saveStorageData(res.message, true, MSG.SESSION_STORAGE);
          dispatch(setServerMsg(res.message, MSG.TYPE_SUCCESS, true));
          if (setPaymentCallback) {
            setPaymentCallback();
          }
        } else {
          window.scrollTo(0, 0);
          dispatch(setServerMsg(res.error, MSG.TYPE_ERROR, true, true));
          dispatch(
            FormActions.setDataMerge(formGroupName, {}, {
              username: [error]
            })
          );
        }
      },
      ({ status }) => genericErrorHandler(dispatch, status));
  };

export const submitForgotPassword = (formData) =>
  dispatch =>
    ajaxRequest('POST', forgotPasswordSubmitUrl, formData).then(
      (res) => {
        if (res.success) {
          hashHistory.push(FORGOT_PASSWORD_CONFIRMATION_URL);
        } else {
          window.scrollTo(0, 0);
          dispatch(setServerMsg(res.error, MSG.TYPE_ERROR, true));
        }
      },
      ({ status }) => genericErrorHandler(dispatch, status)
    );

export const submitResetPassword = (formData) =>
  dispatch =>
    ajaxRequest('POST', resetPasswordSubmitUrl + window.location.search, formData).then(
      (res) => {
        if (res.success) {
          saveStorageData(MSG.RESET_PASSWORD_SUCCESS, {
            [MSG.TYPE_SUCCESS]: 'account.updatePassword.success.message'
          }, MSG.SESSION_STORAGE);
          window.location.replace(loginUrl);
        } else {
          window.scrollTo(0, 0);
          dispatch(setServerMsg(res.error, MSG.TYPE_ERROR, true));
        }
      },
      ({ status }) => genericErrorHandler(dispatch, status));

export const onSuccessMsg = messageID => dispatch => dispatch(setServerMsgID(messageID, MSG.TYPE_SUCCESS));

export const getMemberIdLookup = (memberId) => (dispatch) => {
  dispatch({ type: MEMBERID_LOOKUP });

  dispatch(disabledSubmitButtonDispatch());

  ajaxRequest('POST', MEMBERID_LOOKUP_URL, { memberId })
    .then((res) => {
      dispatch({ type: MEMBERID_LOOKUP_RESULT, data: res });
      dispatch(enabledSubmitButton());
    },
    ({ status }) => genericErrorHandler(dispatch, status)
    );
};

export const memberIdLookup = (param) => (dispatch) => {
  dispatch(getMemberIdLookup(param));
};

export const setUpdateMemberIdEvent = () => (dispatch) => {
  dispatch({ type: MEMBER_UPDATE_STATE, data: true });
};

export const getMemberLookupByPostCode = (postCode, referralId, deliverByReferralId) => (dispatch) => {
  dispatch({ type: MEMBERID_LOOKUP_LOAD_STATE });

  dispatch(disabledSubmitButtonDispatch());

  const formData = {
    postCode,
    referralId,
    deliverByReferralId: String(deliverByReferralId)
  };

  ajaxRequest('POST', MEMBERID_LOOKUP_POST_CODE_STATE_URL, formData)
    .then((res) => {
      if (res.success === true) {
        dispatch({ type: MEMBERID_LOOKUP_GET_STATE_RESULT, data: res });
        dispatch(enabledSubmitButton());
      } else {
        dispatch({ type: MEMBERID_LOOKUP_ERROR_GET_STATE_RESULT, data: {} });
        dispatch(enabledSubmitButton());
      }
    });
};

export const loadMemberIdLookup = (email) => (dispatch) => {
  dispatch({ type: MEMBERID_LOOKUP_LOAD_STATE });

  dispatch(disabledSubmitButtonDispatch());

  ajaxRequest('GET', `${MEMBERID_LOOKUP_LOAD_STATE_URL}/${email}`, {})
    .then((res) => {
      if (res.success === true) {
        dispatch({ type: MEMBERID_LOOKUP_LOAD_STATE_RESULT, data: res });
      } else {
        dispatch({ type: MEMBERID_LOOKUP_ERROR_LOAD_STATE_RESULT, data: {} });
      }
      dispatch(enabledSubmitButton());
    });
};

export const updateMemberIdLookup = (email, referralId, deliverByReferralId, deliveryMode, postCode) => (dispatch) => {
  dispatch({ type: MEMBERID_LOOKUP_LOAD_STATE });

  dispatch(disabledSubmitButtonDispatch());

  const formData = {
    email,
    referralId,
    deliverByReferralId: String(deliverByReferralId),
    deliveryMode,
    postCode
  };

  ajaxRequest('POST', MEMBERID_UPDATE_LOAD_STATE_URL, formData)
    .then((res) => {
      if (res.success === true) {
        dispatch({ type: MEMBERID_LOOKUP_UPDATE_STATE_RESULT, data: res });
      } else {
        dispatch({ type: MEMBERID_LOOKUP_ERROR_UPDATE_STATE_RESULT, data: {} });
      }
      dispatch(enabledSubmitButton());
    });
};

export const memberIdChanged = (value) => (dispatch) => {
  dispatch({ type: MEMBERID_CHANGED, data: value });
};

export const memberIdPrepopulated = () => (dispatch) => {
  dispatch({ type: MEMBERID_LOOKUP_PREPOPULATED });
};

export const memberOptionBoxChange = (value) => (dispatch) => {
  dispatch({ type: MEMBERID_OPTIONBOX_CHANGE, data: value });
};

