import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const ForgotPasswordConfirmation = ({ email, hintTxtId, loginUrl }) => (
  <Fragment>
    <h1 className="page-title">
      <FormattedMessage id="security.ui.forgotPassword.confirmation.title" description="Thanks" />
    </h1>
    <div className="auth-form">
      <p className="text-center">
        {
          email ?
            <FormattedMessage id="security.ui.forgotPassword.confirmation.description" description="We've sent a link to..." values={{ 0: email }} /> :
            <FormattedMessage id="security.ui.forgotPassword.confirmation.description.noEmail" description="Follow the link in the email..." />
        }
      </p>
      <p className="text-center"><FormattedMessage id="security.ui.forgotPassword.confirmation.description2" description="If you can't see the email from us..." /></p>
      <p className="text-center"><FormattedMessage id="security.ui.forgotPassword.confirmation.description3" description="If you can't see the email from us..." /></p>
      <Link className="button button--primary button--stretch forgot-password-back-button" to={loginUrl} href={loginUrl}>
        <FormattedMessage id={hintTxtId} description="Back to sign in" />
      </Link>
    </div>
  </Fragment>
);

ForgotPasswordConfirmation.propTypes = {
  hintTxtId: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  email: PropTypes.string
};

export default ForgotPasswordConfirmation;
