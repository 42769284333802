import * as CONST from './nameFormGroupConstants';

const initialState = {
  titles: {}
};

const nameFormGroup = (state = initialState, action) => {
  switch (action.type) {
    case CONST.SET_TITLES:
      return {
        ...state,
        titles: action.titles
      };
    default:
      return state;
  }
};

export default nameFormGroup;
