import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import OptionIntl from 'app/formComponents/optionIntl/optionIntl';
import { FormError, FormActions, FormGroup } from 'react-forms';
import genericErrorHandler from 'app/utils/serverErrors';
import { simpleAjaxRequest } from 'app/utils/helpers';
import { sortBy, find } from 'lodash';

const { getTownsUrl } = window.inlineGlobalConfig;

class CitySelectorComponent extends Component {

  constructor(props) {
    super();
    this.state = {
      cityInputInUse: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRegion } = nextProps;

    if (selectedRegion !== this.props.selectedRegion) {
      this.props.removeCities();
      this.props.getOptions(this.props.postalCodesUrl, selectedRegion);
    }

    const {
      setInputValue,
      formName,
      cities
    } = nextProps;

    const hasSingleOption = cities && cities.length === 1;
    if (hasSingleOption) {
      setInputValue(formName, {
        city: cities[0].id
      });
    }
  }

  onChange(event) {
    const cityToUse = find(this.props.cities, currentCity => currentCity.id === event.target.value);
    this.props.fetchingColonias();
    this.setState({
      cityInputInUse: true,
    });
    this.props.onChange(cityToUse);
    this.props.changeInputCity(this.props.formName, cityToUse.id, cityToUse.name);
  }

  render() {
    const {
      cities,
      selectedCity,
      selectedCityName,
      ...formProps
    } = this.props;

    return (
      <FormGroup
        {...formProps}
      >
        <div className="form-input">
          <div className="form-input__input-used">
            {this.state.cityInputInUse ?
              <label htmlFor="city" className="mini-label">
                <FormattedMessage id="security.address.city.label" />
              </label> : ''}
            <input
              type="hidden"
              id="cityName"
              name="cityName"
              value={selectedCityName !== null ? selectedCityName : ''}
            />
            <select
              className="city-selector__select"
              name="city"
              id="city"
              value={selectedCity !== null ? selectedCity : undefined}
              onChange={this.onChange}
            >
              <OptionIntl value="" id="addressForm.city.default" />
              {
                cities && cities.length ?
                  cities.map((city) => <option key={`city-${city.id}`} value={city.id}>{city.name}</option>) : ''
              }
            </select>
          </div>
          <FormError forInput="city" />
        </div>
      </FormGroup>
    );
  }
}

CitySelectorComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  handleValidForm: PropTypes.func.isRequired,
  handleInvalidForm: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  selectedRegion: PropTypes.string,
  cities: PropTypes.array,
  selectedCity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  selectedCityName: PropTypes.string,
  getOptions: PropTypes.func.isRequired,
  removeCities: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  postalCodesUrl: PropTypes.string
};

// ------------------------------------------------------------
// CONSTANTS
// ------------------------------------------------------------

// Cities
const SET_AVAILABLE_CITIES = 'SET_AVAILABLE_CITIES';
const SET_CURRENT_CITY = 'SET_CURRENT_CITY';
const REMOVE_CITIES = 'REMOVE_CITIES';
const FETCHING_COLONIAS = 'FETCHING_COLONIAS';

// ------------------------------------------------------------
// ACTIONS
// ------------------------------------------------------------

const getCitiesURL = (basePostcodesURL, regionID) => `${getTownsUrl}/?isoCode=${regionID}`; // `${basePostcodesURL}estados/${regionID}/municipios.json`;

const removeCities = () => ({
  type: REMOVE_CITIES
});

const setCities = (data) => ({
  type: SET_AVAILABLE_CITIES,
  data
});

const setCity = (cityToUse) => ({
  type: SET_CURRENT_CITY,
  data: cityToUse
});


export const fetchingColonias = () => ({
  type: FETCHING_COLONIAS
});


const changeInputCity = (cityFormName, city, cityName) =>
  dispatch => {
    dispatch(FormActions.setInputValue(cityFormName, { city: city, cityName: cityName }));
  };


const getOptions = (url) => dispatch =>
  simpleAjaxRequest('GET', url).then((res) => (dispatch(setCities(res))), ({ status }) => genericErrorHandler(dispatch, status));

// ------------------------------------------------------------
// REDUCER
// ------------------------------------------------------------

// export const getOptions = (url, callback) => (dispatch) => dispatch(callback(demoCities));
const initialState = {
  cities: [],
  selectedCity: null,
  selectedCityName: null,
};

const normalizeCities = cities => {

  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base'
  });

  let allCities = [...cities];

  return allCities.sort((a, b) => (collator.compare(a.name, b.name)));
}

export const CitySelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_CITIES:
      return {
        ...state,
        cities: normalizeCities(action.data)
      };
    case REMOVE_CITIES:
      return {
        ...state,
        selectedCity: null,
        selectedCityName: null,
        cities: []
      };
    case SET_CURRENT_CITY:
      return {
        ...state,
        selectedCity: action.data.id && action.data.id,
        selectedCityName: action.data.name && action.data.name
      };

    case FETCHING_COLONIAS:
      return {
        ...state,
        fetchingColoniasSpinner: true,
      }
    default:
      return state;
  }
};

// ------------------------------------------------------------
// CONTAINER
// ------------------------------------------------------------
const mapStateToProps = (state, props) => {

  const { selectedRegion } = props;
  const { cities, selectedCity, selectedCityName } = state.PostCodeSuggestion.CitySelector;
  const { fetchingColoniasSpinner } = state.PostCodeSuggestion.ColoniaSelector;

  return {
    selectedRegion,
    cities,
    selectedCity,
    selectedCityName,
    setInputValue: FormActions.setInputValue,
    fetchingColoniasSpinner,
    ...FormActions
  };
};

const mapDispatchToProps = (dispatch) => ({

  fetchingColonias: () => { dispatch(fetchingColonias()) },

  getOptions: (baseURL, region) => {
    dispatch(getOptions(getCitiesURL(baseURL, region)));
  },
  onChange: (cityToUse) => {
    dispatch(setCity(cityToUse));
  },
  removeCities: () => {
    dispatch(removeCities());
  },
  changeInputCity: (cityFormName, city, cityName) => {
    dispatch(changeInputCity(cityFormName, city, cityName));
  }
});

export const CitySelector = connect(mapStateToProps, mapDispatchToProps)(CitySelectorComponent);
