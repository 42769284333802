
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const OptionIntl = ({ id, value }) => (
  <FormattedMessage id={id} key={value + id}>
    {(message) => (<option value={value}>{message}</option>)}
  </FormattedMessage>
);

OptionIntl.defaultProps = {
  value: ''
};

OptionIntl.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default OptionIntl;
