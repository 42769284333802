import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormError, FormGroup } from 'react-forms';

class PhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cellphoneNumberInputInUse: false,
      phoneNumberInputInUse: false,
    };
    this.selectInputInUseHandler = this.selectInputInUseHandler.bind(this);
    this.sectionInputInUseHandler = this.sectionInputInUseHandler.bind(this);
  }

  selectInputInUseHandler(input, value) {
    let inputStateHandler = {
      ...this.state
    };

    switch (input) {
      case 'cellphoneNumber':
        inputStateHandler = {
          ...inputStateHandler,
          cellphoneNumberInputInUse: value,
        };
        break;
      case 'phoneNumber':
        inputStateHandler = {
          ...inputStateHandler,
          phoneNumberInputInUse: value,
        };
        break;
      default:
        return inputStateHandler;
    }
    return inputStateHandler;
  }

  sectionInputInUseHandler(event, input) {
    event.preventDefault();
    let inputStateHandler;

    if (event.target.value.length > 0) {
      inputStateHandler = this.selectInputInUseHandler(input, true);
    } else {
      inputStateHandler = this.selectInputInUseHandler(input, false);
    }

    this.setState({
      cellphoneNumberInputInUse: inputStateHandler.cellphoneNumberInputInUse,
      phoneNumberInputInUse: inputStateHandler.phoneNumberInputInUse,
    });
  }
  render() {
    return (
      <FormGroup
        {...this.props}
      >
        <div className="row">
          <div className="columns small-6">
            <div className="form-input">
              <div className="form-input__input-used">
                {this.state.cellphoneNumberInputInUse ? <label htmlFor="cellPhoneNumber.label" className="mini-label"><FormattedMessage id="security.address.cellPhoneNumber" /></label> : ''}
                <input className="form-input" type="tel" name="cellPhoneNumber" id="cellPhoneNumber" value="" required placeholder="*Teléfono celular" onChange={event => this.sectionInputInUseHandler(event, 'cellphoneNumber')} />
              </div>
              <FormError forInput="cellPhoneNumber" />
            </div>
          </div>

          <div className="columns small-6">
            <div className="form-input">
              <div className="form-input__input-used">
                {this.state.phoneNumberInputInUse ? <label htmlFor="phone" className="mini-label"><FormattedMessage id="security.address.phoneNumber.label" /></label> : ''}
                <input className="form-input" type="tel" name="phone" id="phone" value="" required placeholder="Teléfono fijo" onChange={event => this.sectionInputInUseHandler(event, 'phoneNumber')} />
              </div>
              <FormError forInput="phone" />
            </div>
          </div>
        </div>
      </FormGroup>
    );
  }
}

export default PhoneNumber;
