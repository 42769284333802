import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroupAsync } from 'react-forms';
import { scrollToError } from '../../formComponents/formComponentsUtils';
import LoginForm from './loginForm';
import * as AuthFormActions from '../../auth/authFormActions';
import { connect } from 'react-redux';

class LoginFormContainer extends Component {
  constructor(props) {
    super(props);

    this.formGroups = new FormGroupAsync({
      formName: 'Login',
      groups: ['usernameAndPassword']
    });

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const { triggerValidate, submitLogin, cleanCheckoutGuest } = this.props;
    this.formGroups
      .each(group => triggerValidate(group))
      .then((response) => {
        cleanCheckoutGuest();
        return response;
      })
      .then(response => submitLogin(response))
      .fail(() => scrollToError('.auth-form--login'));
  }

  render() {
    const {
      validation, migrationMsg, forgotPasswordUrl, placeholders, buttonDisabled
    } = this.props;

    return (
      <LoginForm
        onSubmit={this.onSubmit}
        formGroups={this.formGroups}
        validation={validation}
        forgotPasswordUrl={forgotPasswordUrl}
        migrationMsg={migrationMsg}
        placeholders={placeholders}
        buttonDisabled={buttonDisabled}
      />
    );
  }
}

LoginFormContainer.propTypes = {
  submitLogin: PropTypes.func.isRequired,
  cleanCheckoutGuest: PropTypes.func.isRequired,
  triggerValidate: PropTypes.func.isRequired,
  migrationMsg: PropTypes.string,
  forgotPasswordUrl: PropTypes.string,
  placeholders: PropTypes.object,
  validation: PropTypes.object,
  buttonDisabled: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
  cleanCheckoutGuest: (formData) => dispatch(AuthFormActions.cleanCheckoutGuest(formData)),
});

export default connect(null, mapDispatchToProps)(LoginFormContainer);
